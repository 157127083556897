import React, { useEffect, useState } from 'react';
import { useUsuario } from '../context/usuario'; // Importa el hook useUsuario
import './Home.css';

const Home = () => {
  const { user, loading } = useUsuario();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const isLoggedIn = !!user;

  if (isLoading) {
    return <div>Cargando$...</div>;
  }

  return (
    <div className="home-container">
      <main className="main-content">
        <div className="left-content">
          <img src="img/home-tubim.jpg" alt="Descripción de la imagen" className="image"/>
        </div>
        <div className="right-content">
          <h2>Plataforma de Reportes BIM</h2>
          <h2 className="color-blue">Administración, Visualización</h2> 
          <h2 className="title-3">y Seguimiento de tus Proyectos.</h2>
          <br />
          <p>Te damos rápido acceso a los datos de </p>
          <p>tu plataforma y de tus clientes.</p>
          <br />
          <div className="buttons">
            {isLoggedIn ? (
              <button className="btn-tubim-dashboard" onClick={() => window.location.href = '/dashboard'}>Mi Dashboard</button>
            ) : (
              <>
                <button className="btn-tubim-one" onClick={() => window.location.href = '/registro'}>Nuevo usuario</button>
                <button className="btn-tubim-two" onClick={() => window.location.href = '/ingresar'}>Usuarios Registrados</button>
              </>
            )}
          </div>
        </div>
      </main>
      <section className="features">
        <div className="feature">
          <h4>Ingreso de Clientes</h4>
          <p>Crea nuevos usuarios, personaliza sus opciones y visualiza sus proyectos.</p>
        </div>
        <div className="feature">
          <h4>Crea tus Proyectos</h4>
          <p>Crea nuevos proyectos, personaliza sus opciones y visualiza sus proyectos.</p>
        </div>
        <div className="feature">
          <h4>Actualiza tus Reportes</h4>
          <p>Crea nuevos reportes, personaliza sus opciones y visualiza sus proyectos.</p>
        </div>
      </section>
      <footer className="footer">
        <p>BIM: Building Information Modeling</p>
        <p>STUDIO te permite crear tus propios elementos basados en formas SVG y ajustarlos para su visualización en dispositivos responsivos.</p>
      </footer>
    </div>
  );
};

export default Home;