import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useUsuario } from '../../context/usuario';
import { useChat } from '../../context/ChatContext';
import stars from '../../assets/img/stars.png';
import chatIcon from '../../assets/img/chat.png';
import Volver from '../Volver';

// Configura el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const Reporte = () => {
  const { proyectoKey, reporteKey } = useParams();
  const { user, loading: usuarioLoading } = useUsuario();
  const { toggleChat } = useChat();

  const [reporte, setReporte] = useState(null);
  const [proyecto, setProyecto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const fetchReporteAndDetails = useCallback(async () => {
    if (!reporteKey) {
      setError('reporteKey no están definidos');
      setLoading(false);
      return;
    }

    try {
      const { data: reporteData, error: reporteError } = await supabase
        .from('reportes')
        .select('*, proyectos(proyecto, url_imagen)')
        .eq('key', reporteKey)
        .single();

      if (reporteError) {
        setError(reporteError.message);
        setLoading(false);
        return;
      }

      setReporte(reporteData);
      setProyecto(reporteData.proyectos);

    } catch (error) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  }, [reporteKey]);

  const checkAdminPermissions = useCallback(async () => {
    if (!user?.id) {
      setError('user.id no están definidos');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('colaboradores')
        .select('perfil')
        .eq('proyecto_key', proyectoKey)
        .eq('usuario_id', user.id)
        .single();

      if (error) {
        console.error("Error en la consulta a la base de datos:", error.message || error);
        setError(error.message || 'Error en la consulta a la base de datos');
        return;
      }

      if (data?.perfil === 'admin') {
        // setIsAdmin(true); // Eliminado ya que no se usa
      } else {
        console.log("El usuario no es administrador");
      }
    } catch (error) {
      console.error("Error al verificar permisos de administrador:", error.message || error);
      setError(error.message || 'Error checking admin permissions.');
    }
  }, [user?.id, proyectoKey]);

  useEffect(() => {
    if (user) {
      fetchReporteAndDetails();
      checkAdminPermissions();
    }
  }, [proyectoKey, reporteKey, user, fetchReporteAndDetails, checkAdminPermissions]);

  useEffect(() => {
    if (reporte) {
      console.log(reporte);
      console.log(reporte?.proyectos);
    }
  }, [reporte]);

  const handleDelete = async () => {
    if (inputValue !== reporte.reporte) {
      setError('El nombre del reporte no coincide.');
      return;
    }

    try {
      const { error } = await supabase
        .from('reportes')
        .delete()
        .eq('key', reporteKey);

      if (error) {
        setError(error.message);
        return;
      }

      // Redirigir o actualizar la UI después de la eliminación
      window.location.href = '/';
    } catch (error) {
      setError('Error deleting report.');
    }
  };

  if (loading || usuarioLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-10 mb-20">
      <div className="relative w-full flex flex-col items-center">
        <Volver titulo={`Reporte ${reporte.reporte}`} />
        <span className="text-2xl font-bold mt-10">
          {proyecto.proyecto}
        </span>
        <a href={`/proyecto/${proyectoKey}`}>
          <img src={proyecto.url_imagen} alt="Imagen del Proyecto" className="w-20 h-20 rounded mb-4 mt-4" />
        </a>
        <span
          className="cursor-pointer ml-2 fixed bottom-4 right-16"
          onClick={() => toggleChat(proyectoKey, proyecto.proyecto, proyecto.url_imagen)}
        >
          <img src={chatIcon} alt="Chat Icon" className="w-10 h-10" />
        </span>
      </div>
      {reporte && (
        <div className=" text-center mb-6">
          <div className="flex justify-center">
            {reporte.descripcion && (
              <p className="text-lg text-gray-400 mb-4 text-center">
                <strong> {reporte.descripcion}</strong>
              </p>
            )}
          </div>
          <div className="flex justify-center">
            <p className="text-sm text-gray-500 mb-2 text-center">
              <strong>Fecha de Creación:</strong> {new Date(reporte.fecha_creacion).toLocaleDateString()} {new Date(reporte.fecha_creacion).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </p>
          </div>
          <div className="flex justify-center space-x-20 mt-20">
            {reporte.url_archivo1 && (
              <div className="flex flex-col items-center">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg">
                    {reporte.extension_archivo1 || 'N/A'}
                  </span>
                </div>
                <a href={reporte.url_archivo1} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {reporte.nombre_archivo1 || 'Archivo no disponible'}
                </a>
              </div>
            )}
            {!reporte.url_archivo1 && (
              <div className="tooltip" data-tip="sin archivo">
                <div className="flex flex-col items-center">
                  <div className="relative">
                    <span className="material-symbols-outlined text-6xl">draft</span>
                    <span className="absolute inset-0 flex items-center justify-center text-white text-lg"></span>
                  </div>
                  <p className="text-gray-500">Archivo 1</p>
                </div>
              </div>
            )}
            {reporte.url_archivo2 && (
              <div className="flex flex-col items-center">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg">{reporte.extension_archivo2}</span>
                </div>
                <a href={reporte.url_archivo2} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{reporte.nombre_archivo2}</a>
              </div>
            )}
            {!reporte.url_archivo2 && (
              <div className="tooltip" data-tip="sin archivo">
              <div className="flex flex-col items-center">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg"></span>
                </div>
                <p className="text-gray-500">Archivo 2</p>
              </div>
            </div>
            )}
            {reporte.url_archivo3 && (
              <div className="flex flex-col items-center">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg">{reporte.extension_archivo3}</span>
                </div>
                <a href={reporte.url_archivo3} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{reporte.nombre_archivo3}</a>
              </div>
            )}
            {!reporte.url_archivo3 && (
              <div className="tooltip" data-tip="sin archivo">
              <div className="flex flex-col items-center">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg"></span>
                </div>
                <p className="text-gray-500">Archivo 3</p>
              </div>
            </div>
            )}
          </div>
          {reporte.url_modelo1 && (
            <div className="flex flex-col items-center mt-20 w-full mb-20">
              <div className="relative w-full flex justify-center">
                <span className="material-symbols-outlined text-6xl">draft</span>
                <span className="absolute inset-0 flex items-center justify-center text-white text-lg">{reporte.extension_modelo1}</span>
              </div>
              <a href={reporte.url_modelo1} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline text-2xl w-full text-center">Modelo 1</a>
            </div>
          )}
          {!reporte.url_modelo1 && (
              <div className="tooltip" data-tip="sin archivo">
              <div className="flex flex-col items-center mt-20">
                <div className="relative">
                  <span className="material-symbols-outlined text-6xl">draft</span>
                  <span className="absolute inset-0 flex items-center justify-center text-white text-lg"></span>
                </div>
                <p className="text-gray-500">Modelo 3d</p>
              </div>
            </div>
          )}
          <div className="flex justify-center space-x-20 mt-20">
            {reporte.url_recorrido1 ? (
              <div className="flex flex-col items-center">
                <span className="material-symbols-outlined text-6xl">route</span>
                <a href={reporte.url_recorrido1} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Recorrido 1</a>
              </div>
            ) : (
              <div className="tooltip" data-tip="sin archivo">
                <div className="flex flex-col items-center">
                  <span className="material-symbols-outlined text-6xl">route</span>
                  <p className="text-gray-500">Recorrido 1</p>
                </div>
              </div>
            )}
            {reporte.url_recorrido2 ? (
              <div className="flex flex-col items-center">
                <span className="material-symbols-outlined text-6xl">route</span>
                <a href={reporte.url_recorrido2} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Recorrido 2</a>
              </div>
            ) : (
              <div className="tooltip" data-tip="sin archivo">
                <div className="flex flex-col items-center">
                  <span className="material-symbols-outlined text-6xl">route</span>
                  <p className="text-gray-500">Recorrido 2</p>
                </div>
              </div>
            )}
            {reporte.url_recorrido3 ? (
              <div className="flex flex-col items-center">
                <span className="material-symbols-outlined text-6xl">route</span>
                <a href={reporte.url_recorrido3} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Recorrido 3</a>
              </div>
            ) : (
              <div className="tooltip" data-tip="sin archivo">
                <div className="flex flex-col items-center">
                  <span className="material-symbols-outlined text-6xl">route</span>
                  <p className="text-gray-500">Recorrido 3</p>
                </div>
              </div>
            )}
          </div>
          {reporte.nota_puntos1 && (
            <div className="flex justify-center items-center mt-20">
              <img 
                src={stars} 
                alt="Estrella" 
                className="w-12 h-12 mr-4" 
              />
              <p className="text-2xl text-gray-300">{reporte.nota_puntos1}</p>
            </div>
          )}
          {reporte.url_mapa_calor && (
            <div className="flex justify-center mt-20">
              <img 
                src={reporte.url_mapa_calor} 
                alt="Mapa de Calor" 
                className="w-full h-auto" 
              />
            </div>
          )}
          <p className="text-sm text-gray-400 mt-20"><strong>Encargado:</strong> {reporte.encargado}</p>
          <p className="text-sm text-gray-400"><strong>Cargo:</strong> {reporte.cargo}</p>
          {reporte.url_qr && (
            <div className="flex justify-center mt-4">
              {reporte.link_qr ? (
                <a href={reporte.link_qr} target="_blank" rel="noopener noreferrer">
                  <img src={reporte.url_qr} alt="QR Code" className="w-32 h-32" />
                </a>
              ) : (
                <img src={reporte.url_qr} alt="QR Code" className="w-32 h-32" />
              )}
            </div>
          )}
          {reporte.fecha && <p className="text-sm text-gray-400"><strong>Fecha:</strong> {new Date(reporte.fecha).toLocaleDateString()}</p>}
        </div>
      )}
      <div className="flex justify-center">
        <button onClick={() => setShowModal(true)} className="bg-red-500 text-white px-4 py-2 mt-20 mb-20 rounded hover:bg-red-600 transition">
          Eliminar Reporte
        </button>
      </div>

      {showModal && (
        <dialog open className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="text-lg font-bold">Confirmar Eliminación</h3>
            <p className="py-4">Por favor, ingrese el nombre del reporte para confirmar la eliminación:</p>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="input input-bordered w-full mb-4"
            />
            <div className="modal-action">
              <button onClick={() => setShowModal(false)} className="btn">Cancelar</button>
              <button onClick={handleDelete} className="btn btn-error">Eliminar</button>
            </div>
          </div>
        </dialog>
      )}
    </div>
  );
};

export default Reporte;
