import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient'; // Asegúrate de importar tu cliente de Supabase

const AceptarInvitacion = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // Nuevo estado para la confirmación de contraseña
  const [error, setError] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [usuarioExiste, setusuarioExiste] = useState(null); // Nuevo estado para almacenar el usuario existente
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const proyecto = searchParams.get('proyecto');
  const navigate = useNavigate();
  const [proyectoData, setProyectoData] = useState(null); // Nuevo estado para almacenar la información del proyecto
  const [creadorData, setCreadorData] = useState(null); // Nuevo estado para almacenar la información del creador
  const [loading, setLoading] = useState(false); // Nuevo estado para manejar la carga

  useEffect(() => {
    const checkUserExists = async () => {
      // Validar que el email y el proyecto coincidan con la información de la tabla 'invitados'
      const { data: invitado, error: invitadoError } = await supabase
      .from('invitados')
      .select('*')
      .eq('email', email)
      .eq('proyecto_key', proyecto)
      .single();

      if (invitadoError || !invitado) {
        navigate('/'); // Redirigir al inicio si no coincide
        return;
      }
      
      const { data: usuarioExiste, error: userError } = await supabase
        .from('usuarios')
        .select('*')
        .eq('email', email)
        .single();

      if (userError && userError.code !== 'PGRST116') {
        throw new Error(`Error al consultar la tabla usuarios: ${userError.message}`);
      }

      setUserExists(!!usuarioExiste); // Actualiza el estado de userExists
      setusuarioExiste(usuarioExiste); // Almacena el usuario existente

      // Obtener información del proyecto
      const { data: proyectoData, error: proyectoError } = await supabase
        .from('proyectos')
        .select('*')
        .eq('key', proyecto) // Asegúrate de que 'key' sea el campo correcto para filtrar
        .single();

      if (proyectoError) {
        console.error(`Error al consultar la tabla proyectos: ${proyectoError.message}`);
        return;
      }

      setProyectoData(proyectoData); // Almacena la información del proyecto

      // Obtener información del usuario que ha invitado
      const { data: creadorData, error: creadorError } = await supabase
        .from('usuarios')
        .select('*')
        .eq('user_id', proyectoData.creador) // Usar el creador del proyecto
        .single();

      if (creadorError) {
        console.error(`Error al consultar la tabla usuarios: ${creadorError.message}`);
        return;
      }

      setCreadorData(creadorData); // Almacena la información del creador
    };

    checkUserExists();
  }, [email, proyecto, navigate]); // Agregar 'navigate' a las dependencias

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Las contraseñas no son iguales.');
      return;
    }

    setLoading(true); // Iniciar el estado de carga

    if (userExists) {
      // Lógica para aceptar la invitación
      const { error: updateError } = await supabase
        .from('invitados')
        .update({ estado_invitacion: 'invitacion aceptada' })
        .eq('email', email)
        .eq('proyecto_key', proyecto);

      if (updateError) {
        setError(`Error al actualizar la invitación: ${updateError.message}`);
        return;
      }

      const { data: invitado, error: invitadoError } = await supabase
        .from('invitados')
        .select('perfil')
        .eq('email', email)
        .eq('proyecto_key', proyecto) // Filtrar por proyecto_key
        .single();

      if (invitadoError) {
        setError(`Error al consultar la tabla invitados: ${invitadoError.message}`);
        return;
      }

      const { error: insertColaboradorError } = await supabase
        .from('colaboradores')
        .insert([{ proyecto_key: proyecto, usuario_id: usuarioExiste.user_id, perfil: invitado.perfil }]); // Obtener perfil del campo 'perfil' en 'invitados'

      if (insertColaboradorError) {
        setError(`Error al insertar en la tabla colaboradores: ${insertColaboradorError.message}`);
        return;
      }

      console.log('Usuario ya existente, invitación aceptada y colaborador agregado.');
      navigate('/mis-datos');
    } else {
      console.log('Usuario no existente, creando nuevo usuario');
      console.log('email:', email);
      console.log('password:', password);

      // Lógica para crear un nuevo usuario
      const { data: user, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
      }, {
        emailRedirectTo: window.location.origin + '/ingresar'
      });

      if (signUpError) {
        setError(signUpError.message.includes('Password should be at least 6 characters') 
            ? 'La contraseña debe tener al menos 6 caracteres.' 
            : signUpError.message);
        setLoading(false); // Finalizar el estado de carga
        return;
      }

      try {
        // Confirmar el usuario
        const { error: rpcError } = await supabase.rpc('confirm_user_email', {
          user_id: user.user.id,
        });

        if (rpcError) {
          throw new Error(`Error al activar el usuario: ${rpcError.message}`);
        }

        // Aquí podrías realizar la consulta a la tabla 'invitados'
        const { data: invitadoData, error: invitadoError } = await supabase
          .from('invitados')
          .select('*')
          .eq('email', user.user.email) // Asegúrate de que el filtro sea correcto
          .eq('proyecto_key', proyecto) // Asegúrate de filtrar también por proyecto
          .single(); // Asegúrate de que solo se devuelva un registro

        if (invitadoError) {
          throw new Error(`Error al consultar la tabla invitados: ${invitadoError.message}`);
        }

        if (invitadoData) {
          console.log('Datos del invitado:', invitadoData);
        } else {
          console.error('No se encontró el invitado con el email proporcionado.');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error.message);
        setLoading(false); // Finalizar el estado de carga
        return;
      }

      const username = email.split('@')[0];
      const url_imagen = "https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-imagen.jpg?t=2024-10-18T18%3A49%3A25.787Z";

      const { error: insertError } = await supabase
        .from('usuarios')
        .insert([{ user_id: user.user.id, email, username, url_imagen }]); // Elimina 'logo' si no existe

      if (insertError) {
        throw new Error(`Error al insertar en la tabla usuarios: ${insertError.message}`);
      }

      // Actualizar el estado de la invitación en la tabla 'invitados'
      const { error: updateError } = await supabase
        .from('invitados')
        .update({ estado_invitacion: 'invitacion aceptada' })
        .eq('email', email)
        .eq('proyecto_key', proyecto);

      if (updateError) {
        throw new Error(`Error al actualizar la invitación: ${updateError.message}`);
      }

      
      const { data: invitado, error: invitadoError } = await supabase
      .from('invitados')
      .select('perfil')
      .eq('email', email)
      .single();

      if (invitadoError) {
        setError(`Error al consultar la tabla invitados: ${invitadoError.message}`);
        return;
      }

      // Insertar el nuevo colaborador en la tabla 'colaboradores'
      const { error: insertColaboradorError } = await supabase
        .from('colaboradores')
        .insert([{ proyecto_key: proyecto, usuario_id: user.user.id, perfil: invitado.perfil }]); // Cambia 'perfil_default' según sea necesario

      if (insertColaboradorError) {
        throw new Error(`Error al insertar en la tabla colaboradores: ${insertColaboradorError.message}`);
      }

      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) {
        throw new Error(`Error al iniciar sesión: ${signInError.message}`);
      }

      navigate('/mis-datos'); // Redirigir a "mis-datos"
    }
    setLoading(false); // Finalizar el estado de carga
  };

  return (
    <div className="container mx-auto p-4 mt-20 mb-20">
      <h2 className='text-2xl font-bold text-center'>Aceptar Invitación</h2>
      <div className='container-mayor'>
        <p className='text-center mb-8'>
          Has sido invitado por <span className='font-bold text-white'>{creadorData?.nombre} {creadorData?.apellido}</span> a colaborar en el proyecto <span className='font-bold text-white'>{proyectoData?.proyecto}</span> 
          <br />
          {!userExists ? (
            <span className='text-xs'>
              Para continuar, crea tu contraseña
            </span>
          ) : (
            <span className='text-xs'>
              Presiona "Aceptar Invitación" para continuar
            </span>
          )}
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" value={email} readOnly className="form-control" />
          </div>
          {!userExists && (
            <>
              <div className="form-group mt-4">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mt-4">
                <label htmlFor="confirmPassword">Validar Contraseña</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
            </>
          )}
          {error && <p className="error text-red-400">{error}</p>}
          <br />
          <button type="submit" className="btn-azul" disabled={loading}>
            {loading ? (
              <>
                Cargando proyecto... <span className="loading loading-spinner loading-xs"></span>
              </>
            ) : (
              userExists ? 'Aceptar Invitación' : 'Crear Cuenta'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AceptarInvitacion;
