import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { supabase } from '../lib/supabaseClient';
import ChatProyecto from '../components/proyectos/ChatProyecto';
import { useChat } from '../context/ChatContext';
import PrivateLayoutSkeleton from './PrivateLayoutSkeleton';

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { chats, toggleChat } = useChat();

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();

      if (error || !user) {
        navigate('/');
      } else {
        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (!userError) {
          setUserData(userData);
        }
        setLoading(false);
      }
    };

    checkUser();
  }, [navigate]);

  if (loading) return <PrivateLayoutSkeleton />;

  console.log('Chats State:', chats);

  const handleToggleChat = (key, nombreProyecto, imagenProyecto) => {
    toggleChat(key, nombreProyecto, imagenProyecto);
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center">
        {children}
      </div>
      <Footer />

      {userData && (
        <div>
          {Object.keys(chats).map((key) => (
            chats[key]?.isOpen && (
              <ChatProyecto 
                key={key}
                proyectoKey={key}
                usuario={userData.user_id}
                urlImagen={userData.url_imagen}
                nombre={userData.nombre}
                apellido={userData.apellido}
                nombreProyecto={chats[key].nombreProyecto}
                imagenProyecto={chats[key].imagenProyecto}
                onClose={() => handleToggleChat(key, chats[key].nombreProyecto, chats[key].imagenProyecto)}
              />
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default PrivateLayout;
