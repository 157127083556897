import React, { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import ReportesSkeleton from './ReportesSkeleton';
import Volver from '../Volver';
import ReportesLayout from './ReportesLayout';

const Reportes = () => {
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useUsuario();
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const fetchReportes = async () => {
      try {
        const { data: colaboradoresData, error: colaboradoresError } = await supabase
          .from('colaboradores')
          .select('proyecto_key')
          .eq('usuario_id', user.id);

        if (colaboradoresError) {
          setError(colaboradoresError.message);
          setLoading(false);
          return;
        }

        const proyectoKeys = colaboradoresData.map(colaborador => colaborador.proyecto_key);

        const { data: reportesData, error: reportesError } = await supabase
          .from('reportes')
          .select('*')
          .in('proyecto_key', proyectoKeys);

        if (reportesError) {
          throw reportesError;
        }

        const promises = reportesData.map(async (reporte) => {
          const { data: proyectoData, error: proyectoError } = await supabase
            .from('proyectos')
            .select('proyecto')
            .eq('key', reporte.proyecto_key)
            .single();

          if (proyectoError) {
            throw proyectoError;
          }

          return {
            ...reporte,
            proyecto: proyectoData,
          };
        });

        const reportesWithData = await Promise.all(promises);

        setReportes(reportesWithData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (user) {
      fetchReportes();
    }
  }, [user]);

  const filteredReportes = reportes.filter((reporte) => {
    return (
      reporte.reporte.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (reporte.cliente && reporte.cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase())) ||
      reporte.proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortedReportes = [...filteredReportes].sort((a, b) => {
    switch (sortOrder) {
      case 'notaAsc':
        return a.nota_puntos1 - b.nota_puntos1;
      case 'notaDesc':
        return b.nota_puntos1 - a.nota_puntos1;
      case 'fechaAsc':
        return new Date(a.fecha_creacion) - new Date(b.fecha_creacion);
      case 'fechaDesc':
        return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
      default:
        return 0;
    }
  });

  if (loading) return <ReportesSkeleton />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-20">
      <Volver titulo="Listados de Reportes" />
      <input
        type="text"
        placeholder="Buscar por reporte, cliente o proyecto"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="input input-bordered mb-4 w-full mt-10"
      />
      <select
        onChange={(e) => setSortOrder(e.target.value)}
        className="text-gray-600 p-3 rounded mb-4"
      >
        <option value="notaAsc">Ordenar por nota (Ascendente)</option>
        <option value="notaDesc">Ordenar por nota (Descendente)</option>
        <option value="fechaAsc">Ordenar por fecha de creación (Ascendente)</option>
        <option value="fechaDesc">Ordenar por fecha de creación (Descendente)</option>
      </select>

      <ReportesLayout reportes={sortedReportes} showMap={showMap} toggleMap={() => setShowMap(!showMap)} />
    </div>
  );
};

export default Reportes;
