import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../assets/animaciones/loadingPrivateLayout.json';
import './PrivateLayoutSkeleton.css';

const PrivateLayoutSkeleton = () => {
  return (
    <div className="lottie-container">
      <Lottie 
        animationData={animationData} 
        loop={true} 
        style={{ width: 300, height: 300 }}
      />
    </div>
  );
};

export default PrivateLayoutSkeleton;