import React from 'react';

const ReportesSkeleton = () => {
  return (
    <div className="container mx-auto p-4 mt-20">
      <div className="animate-pulse">
        <div className="h-6 bg-gray-300 rounded mb-4"></div>
        <div className="h-10 bg-gray-300 rounded mb-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="bg-gray-300 p-4 rounded shadow"></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportesSkeleton;