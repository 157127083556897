import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase, supabaseAdmin } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import './Proyectos.css';
import { motion } from 'framer-motion';
import ProyectosSkeleton from './ProyectosSkeleton';
import stars from '../../assets/img/stars.png';
import Volver from '../Volver';

const Proyectos = () => {
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useUsuario();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [reportCount, setReportCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchPendingInvitations = useCallback(async () => {
    try {
      console.log('Fetching pending invitations for:', user.email);
      const { data: invitaciones, error } = await supabase
        .from('invitados')
        .select('proyecto_key')
        .eq('estado_invitacion', 'invitacion enviada')
        .eq('email', user.email);

      if (error) {
        console.error('Error al obtener invitaciones:', error.message);
        return [];
      }

      console.log('Pending invitations:', invitaciones);
      return invitaciones.map(inv => inv.proyecto_key);
    } catch (error) {
      console.error('Error al obtener invitaciones:', error.message);
      return [];
    }
  }, [user.email]);

  const fetchProyectos = useCallback(async () => {
    try {
      const { data: proyectosExistentes, error: errorExistentes } = await supabase
        .from('proyectos')
        .select(`
          *,
          reportes (
            nota_puntos1
          ),
          invitados (
            estado_invitacion,
            email
          ),
          usuarios:creador (
            username
          )
        `)
        .eq('creador', user.id);

      if (errorExistentes) {
        console.error('Error al obtener proyectos existentes:', errorExistentes.message);
        setError(errorExistentes.message);
        setLoading(false);
        return;
      }

      // Obtener proyectos donde has sido invitado y aceptado
      const { data: proyectosInvitado, error: errorInvitado } = await supabase
        .from('invitados')
        .select('proyecto_key')
        .eq('email', user.email)
        .eq('estado_invitacion', 'invitacion aceptada');

      if (errorInvitado) {
        console.error('Error al obtener proyectos como invitado:', errorInvitado.message);
        setError(errorInvitado.message);
        setLoading(false);
        return;
      }
      

      const proyectoKeysInvitado = proyectosInvitado.map(inv => inv.proyecto_key);

      const { data: proyectosComoInvitado, error: errorProyectosInvitado } = await supabase
        .from('proyectos')
        .select(`
          *,
          reportes (
            nota_puntos1
          ),
          invitados (
            estado_invitacion,
            email
          )
        `)
        .in('key', proyectoKeysInvitado);

                // Obtener proyectos pendientes
      const proyectoKeys = await fetchPendingInvitations();
      const { data: proyectosPendientes, error: errorPendientes } = await supabase
        .from('proyectos')
        .select(`
          *,
          reportes (
            nota_puntos1
          ),
          invitados (
            estado_invitacion,
            email
          ),
          usuarios:creador (
            username
          )
        `)
        .in('key', proyectoKeys);

      if (errorPendientes) {
        console.error('Error al obtener proyectos pendientes:', errorPendientes.message);
        setError(errorPendientes.message);
        setLoading(false);
        return;
      }

      console.log('Proyectos pendientes:', proyectosPendientes);

      if (errorProyectosInvitado) {
        console.error('Error al obtener proyectos como invitado:', errorProyectosInvitado.message);
        setError(errorProyectosInvitado.message);
        setLoading(false);
        return;
      }

      // Obtener datos de colaboradores para obtener el perfil
      const { data: colaboradoresData, error: colaboradoresError } = await supabase
        .from('colaboradores')
        .select('proyecto_key, perfil')
        .eq('usuario_id', user.id);

      if (colaboradoresError) {
        setError(colaboradoresError.message);
        setLoading(false);
        return;
      }

      const todosProyectos = [
        ...proyectosExistentes, 
        ...proyectosComoInvitado, 
        ...proyectosPendientes
      ].map(proyecto => {
        const colaborador = colaboradoresData.find(c => c.proyecto_key === proyecto.key);
        const reportes = Array.isArray(proyecto.reportes) ? proyecto.reportes : [];
        const promedioNotas = reportes.length > 0 
          ? reportes.reduce((acc, reporte) => acc + (reporte.nota_puntos1 || 0), 0) / reportes.length
          : 0;

        return {
          ...proyecto,
          perfil: colaborador ? colaborador.perfil : null,
          promedioNotas: promedioNotas,
          reportCount: reportes.length,
          creadorUsername: proyecto.usuarios ? proyecto.usuarios.username : 'Desconocido'
        };
      });

      console.log('Proyectos con perfil:', todosProyectos); // Verifica los datos de proyectos con perfil
      setProyectos(todosProyectos);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener proyectos:', error.message);
      setError(error.message);
      setLoading(false);
    }
  }, [user.id, fetchPendingInvitations, user.email]);

  useEffect(() => {
    if (!user) return;
    fetchProyectos();
  }, [user, fetchProyectos]);

  const handleDeleteProject = async (projectId) => {
    setIsDeleting(true);
    try {
      const { data: reportesData, error: reportesError } = await supabaseAdmin
        .from('reportes')
        .select('key')
        .eq('proyecto_key', projectId);
        if (reportesError) throw new Error(reportesError.message);
        
        const reportKeys = reportesData.map(reporte => reporte.key);
        
        await supabaseAdmin
        .from('invitados')
        .delete()
        .eq('proyecto_key ', projectId);

        await supabaseAdmin
          .from('colaboradores')
          .delete()
          .eq('proyecto_key', projectId);
          
          await supabaseAdmin
          .from('chat')
          .delete()
          .eq('proyecto', projectId);
          
      if (reportKeys.length > 0) {
        await supabaseAdmin
        .from('reportes')
        .delete()
        .in('key', reportKeys);
      }
      
      const folderPath = `${projectId}/`;

      const { data: files, error: listError } = await supabaseAdmin
        .storage
        .from('proyectos')
        .list(folderPath);

      if (listError) {
        console.error('Error listing files:', listError);
      } else {
        const filePaths = files.map(file => file.name);
        
        if (filePaths.length > 0) {
          const { error: storageError } = await supabaseAdmin
            .storage
            .from('proyectos')
            .remove(filePaths.map(file => `${folderPath}${file}`));

          if (storageError) {
            console.error('Error removing files:', storageError);
          } else {
            console.log("Archivos eliminados exitosamente:", filePaths);
          }
        } else {
          console.log('No hay archivos para eliminar en la carpeta.');
        }
      }

      const { error: deleteError } = await supabase
      .from('proyectos')
      .delete()
      .eq('key', projectId);

      if (deleteError) {
        console.error('Error al eliminar el proyecto:', deleteError.message);
        setError(deleteError.message);
        return;
      }

      const { error: storageError } = await supabaseAdmin
      .storage
      .from('proyectos')
      .remove([`${projectId}`]); 
      
      if (storageError) {
        console.error('Error al eliminar la carpeta del almacenamiento:', storageError.message);
        setError(storageError.message);
        return;
      }

      setProyectos(prevProyectos => prevProyectos.filter(proyecto => proyecto.key !== projectId));
      setIsDialogOpen(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const openDialog = (proyecto) => {
    setProjectToDelete(proyecto.key);
    setReportCount(proyecto.reportCount);
    setIsDialogOpen(true);
  };

  const handleRemoveUserFromProject = async (projectKey) => {
    try {
      const { error } = await supabase
        .from('colaboradores')
        .delete()
        .eq('proyecto_key', projectKey)
        .eq('usuario_id', user.id);

      if (error) {
        console.error('Error al eliminar el perfil del proyecto:', error.message);
        setError(error.message);
      } else {
        setProyectos(prevProyectos => prevProyectos.map(proyecto => {
          if (proyecto.key === projectKey) {
            return {
              ...proyecto,
              perfil: null,
            };
          }
          return proyecto;
        }));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAceptarInvitacion = async (proyectoKey) => {
    try {
      const { error: updateError } = await supabase
        .from('invitados')
        .update({ estado_invitacion: 'invitacion aceptada' })
        .eq('proyecto_key', proyectoKey)
        .eq('email', user.email);

      if (updateError) {
        console.error('Error al aceptar la invitación:', updateError.message);
        return;
      }

      const { error: insertError } = await supabase
        .from('colaboradores')
        .insert([
          {
            proyecto_key: proyectoKey,
            usuario_id: user.id,
            perfil: 'colaborador'
          }
        ]);

      if (insertError) {
        console.error('Error al insertar en colaboradores:', insertError.message);
      } else {
        await fetchProyectos();
      }
    } catch (error) {
      console.error('Error al aceptar la invitación:', error.message);
    }
  };

  const handleRechazarInvitacion = async (proyectoKey) => {
    try {
      const { error } = await supabase
        .from('invitados')
        .update({ estado_invitacion: 'invitacion rechazada' })
        .eq('proyecto_key', proyectoKey)
        .eq('email', user.email);

      if (error) {
        console.error('Error al rechazar la invitación:', error.message);
      } else {
        setProyectos(prevData =>
          prevData.filter(proyecto => proyecto.key !== proyectoKey)
        );
      }
    } catch (error) {
      console.error('Error al rechazar la invitación:', error.message);
    }
  };

  const sortedProyectos = [...proyectos].sort((a, b) => {
    return sortOrder === 'asc' ? a.reportCount - b.reportCount : b.reportCount - a.reportCount;
  });

  const filteredProyectos = sortedProyectos.filter((proyecto) => {
    return (
      proyecto.proyecto.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proyecto.descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (loading) return <ProyectosSkeleton />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-10 mb-20">
      <Volver titulo="Listado de Proyectos" />
      <div className="mb-4 flex justify-center">
        <button
          onClick={() => navigate('/proyectos/nuevo-proyecto')}
          className="bg-[var(--color-atention)] text-white px-6 py-5 rounded-2xl flex items-center mt-12"
        >
          <span className="material-symbols-outlined mr-2">add_circle</span>
          PROYECTO
        </button>
      </div>
      {proyectos.length > 0 ? (
        <>
          <div className="mb-4 flex justify-center items-center mt-12">
            <input
              type="text"
              placeholder="Buscar proyecto"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border p-3 rounded w-full max-w-[400px] text-[#3A3A3A] input-mobile"
            />
            <select
              onChange={(e) => setSortOrder(e.target.value)}
              className="text-[#3A3A3A] p-3 rounded ml-2 select-mobile"
            >
              <option value="reportCountAsc">Ordenar por cantidad de reportes (Ascendente)</option>
              <option value="reportCountDesc">Ordenar por cantidad de reportes (Descendente)</option>
              <option value="fechaCreacionAsc">Ordenar por fecha de creación (Ascendente)</option>
              <option value="fechaCreacionDesc">Ordenar por fecha de creación (Descendente)</option>
              <option value="calificacionAsc">Ordenar por calificación (Ascendente)</option>
              <option value="calificacionDesc">Ordenar por calificación (Descendente)</option>
            </select>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center mt-12">
          <div className="bg-gray-200 p-10 text-center w-4/5 rounded-2xl">
            <h2 className="text-xl font-bold mb-4 text-[var(--color-atention)]">PROYECTOS</h2>
              <p className="text-xl text-[var(--secondary-background)] text-justify pb-10">
                En esta ventana encontrarás todos los proyectos que desarrollas con tus empresas Partners. Aquí podrás acceder de manera rápida y organizada a toda la información relevante de cada proyecto, facilitando el seguimiento y la colaboración. Toda la documentación, reportes BIM, puntuación de proyecto, avances y datos relacionados estarán centralizados para que puedas consultarlos fácilmente, optimizando la gestión y el flujo de trabajo en cada una de tus iniciativas conjuntas.
              </p>
          </div>
        </div>
      )}
      <div className="flex flex-wrap justify-center mt-12">
        {filteredProyectos.map((proyecto) => {
          const invitacionPendiente = proyecto.invitados && proyecto.invitados.some(
            (invitado) => invitado.estado_invitacion === 'invitacion enviada' && invitado.email === user.email
          );

          return (
            <motion.div 
              key={proyecto.id} 
              className="max-w-[380px] bg-white rounded-2xl shadow-md overflow-hidden flex flex-col relative m-1 mb-4 md:m-6 group"
              initial={{ scale: 0 }} 
              animate={{ scale: 1 }} 
              transition={{ duration: 0.3 }}
            >
              <div className="absolute top-1 right-1 md:top-4 md:right-4 text-gray-500 z-10">
                {proyecto.perfil && (
                  <span className="text-sm font-bold mr-10">{proyecto.perfil}</span>
                )}
              </div>
              {!invitacionPendiente && (
                <div className="absolute top-1 right-1 md:top-4 md:right-4 text-gray-500 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <button 
                    className="text-gray-500 hover:text-gray-800" 
                    onClick={() => {
                      if (proyecto.perfil === 'admin') {
                        openDialog(proyecto);
                      } else {
                        handleRemoveUserFromProject(proyecto.key);
                      }
                    }}
                  >
                    <span className="material-symbols-outlined text-4xl">cancel</span>
                  </button>
                </div>
              )}
              <div className="flex flex-1 mitad-arriba mt-1 md:mt-4 ml-1 md:ml-2">
                <div className="relative w-20 h-20 md:w-24 md:h-24 m-2 flex-shrink-0">
                  <img 
                    src={proyecto.url_imagen || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/logos/subir-logo.jpg'} 
                    alt={proyecto.proyecto} 
                    className="rounded-2xl w-full h-full object-cover"
                  />
                  {proyecto.reportCount > 0 && (
                    <div className="flex items-center justify-center mb-2 mt-2">
                      <img src="/img/iconos/ico-reportes.png" alt="Reportes" className="w-5 h-5 opacity-60" />
                      <p className="ml-2 text-gray-600">{proyecto.reportCount}</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col datos-proyecto ml-1 md:ml-2 mt-1 md:mt-4">
                  <h2 className="text-m md:text-xl font-bold pt-4" style={{ color: '#3D3D3C' }}>{proyecto.proyecto}</h2>
                  <p className="text-gray-600" style={{ minHeight: '40px' }}>
                    {proyecto.descripcion && proyecto.descripcion.length > 0 
                      ? (proyecto.descripcion.length > 22 
                          ? `${proyecto.descripcion.substring(0, 22)}...` 
                          : proyecto.descripcion) 
                      : ' '
                    }
                  </p>
                  {invitacionPendiente ? (
                    <div className="invitacion-pendiente">
                      <p>Invitado como Colaborador</p>
                    </div>
                  ) : (
                    proyecto.invitados && proyecto.invitados.length > 0 && (
                      <div className="flex items-center mt-6">
                        {(() => {
                          const estado = proyecto.invitados[0]?.estado_invitacion;
                          if (estado === 'invitacion aceptada') {
                            return <span className="material-symbols-outlined mr-1 md:mr-2" style={{ color: '#4BA9D3' }}>done_all</span>;
                          } else if (estado === 'invitacion enviada') {
                            return <span className="loading loading-spinner loading-xs mr-2"></span>;
                          } else if (estado === 'invitacion rechazada') {
                            return <span className="material-symbols-outlined mr-1 md:mr-2" style={{ color: '#df6c4e' }}>cancel</span>;
                          }
                          return null;
                        })()}
                        <p className="text-gray-400 text-sm md:text-base">{proyecto.correo}</p>
                      </div>
                    )
                  )}
                  {proyecto.celular && (
                    <div className="flex items-center mt-3">
                      <span className="material-symbols-outlined mr-2" style={{ color: '#4BA9D3' }}>phone_iphone</span>
                      <p className="text-gray-400">{proyecto.celular}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="p-4 flex-1 mitad-abajo mt-10 relative">
                {invitacionPendiente ? (
                  <div className="flex flex-col items-center">
                    <p className="w-full text-center mb-2">
                      Fuiste agregado como encargado por el usuario @{proyecto.creadorUsername} <br />
                    </p>
                    <div className="flex justify-center">
                      <button onClick={() => handleAceptarInvitacion(proyecto.key)} className="btn mx-2">Aceptar</button>
                      <button onClick={() => handleRechazarInvitacion(proyecto.key)} className="btn mx-2">Rechazar</button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center mb-2 w-4/5 bg-[#D9E0E5] border border-[#CCC9C9] p-4 rounded-lg link-proyecto">
                      <span className="material-symbols-outlined text-4xl md:text-6xl opacity-60">settings</span>
                      <Link to={`/proyecto/${proyecto.key}`} className="ml-4 md:ml-8 px-1 py-2 rounded text-sm md:text-lg line-height-1 font-bold">VER / EDITAR / AGREGAR REPORTES</Link>
                    </div>
                    <div className="flex items-end justify-start mt-8">
                      <div className="flex items-center ml-2 md:ml-10">
                        <a href={proyecto.url_carpeta} target="_blank" rel="noopener noreferrer" className="flex items-center">
                          <span className="material-symbols-outlined text-4xl md:text-5xl">folder</span>
                          <p className="ml-2 text-sm md:text-base">Carpeta <br /> de proyecto</p>
                        </a>
                      </div>
                      {proyecto.promedioNotas > 0 && (
                        <div className="flex items-center ml-5 md:ml-10">
                          <img 
                            src={stars} 
                            alt="Estrella" 
                            className="w-6 h-6 mr-2" 
                          />
                          <p className="ml-2 text-xl md:text-2xl font-bold text-[#3A3A3A]">
                            {proyecto.promedioNotas.toFixed(1)}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!invitacionPendiente && (
                  <Link to={`/proyectos/editar-proyecto/${proyecto.key}`} 
                    className="material-symbols-outlined circular absolute right-2 md:right-4 bottom-2 md:bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    edit
                  </Link>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
      {isDialogOpen && (
        <dialog open className="modal fixed inset-0 flex items-center justify-center">
          <div className="modal-box">
            <h3 className="text-lg font-bold text-center mb-4">ADVERTENCIA</h3>
            <p>El proyecto <strong>{proyectos.find(p => p.key === projectToDelete)?.proyecto}</strong> tiene asociados <strong>{reportCount}</strong> reportes. Toda la información será borrada y no habrá forma de restaurarla. ¿Desea borrar?</p>
            {isDeleting ? (
              <div className="flex items-center justify-center mt-4">
                <span className="loading loading-spinner loading-xs"></span>
                <span className="ml-2">Borrando...</span>
              </div>
            ) : (
              <div className="modal-action flex justify-between">
                <button className="btn" onClick={() => handleDeleteProject(projectToDelete)}>Sí, borrar</button>
                <button className="btn" onClick={() => setIsDialogOpen(false)}>Cancelar</button>
              </div>
            )}
          </div>
        </dialog>
      )}
    </div>
  );
};

export default Proyectos;
