import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useUsuario } from '../../context/usuario';
import { Loader } from "@googlemaps/js-api-loader";
import { Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

const EditarProyecto = () => {
  const { proyectoKey } = useParams(); // Obtener la clave del proyecto desde los parámetros de la URL
  const [formData, setFormData] = useState({
    proyecto: '',
    descripcion: '',
    fecha_inicio: '',
    fecha_termino: '',
    ubicacion: ''
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, usuario } = useUsuario();
  const [isUpdating, setIsUpdating] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [previousEncargadoEmail, setPreviousEncargadoEmail] = useState(''); // Guardar el correo del encargado anterior
  const [isAdmin, setIsAdmin] = useState(false); // Estado para verificar si es admin

  // Cargar la API de Google Maps
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: libraries,
    });

    loader.load().then(() => {
      setIsApiLoaded(true);
    }).catch((error) => {
      setError("Error al cargar la API de Google Maps");
    });
  }, []);

  // Cargar los datos del proyecto al iniciar
  useEffect(() => {
    const fetchProjectData = async () => {
      const { data, error } = await supabase
        .from('proyectos')
        .select('*') // Asegúrate de que estás seleccionando todos los campos
        .eq('key', proyectoKey) // Usa la clave del proyecto
        .single();

      if (error) {
        setError('Error al obtener el proyecto');
        return;
      }

      // Asegúrate de que los campos 'encargado' y 'correo' estén en 'data'
      // console.log('Datos del proyecto:', data); // Para depuración

      setFormData(data); // Esto debería incluir 'encargado' y 'correo'
      setSelectedValue(data.ubicacion); // Establecer la ubicación seleccionada
      setImageUrl(data.url_imagen); // Cargar la URL de la imagen si existe
      setPreviousEncargadoEmail(data.correo); // Guardar el correo del encargado anterior
    };

    fetchProjectData();
  }, [proyectoKey]);

  // Cargar la verificación de admin al iniciar
  useEffect(() => {
    const checkAdmin = async () => {
      const { data, error } = await supabase
        .from('colaboradores')
        .select('perfil')
        .eq('usuario_id', user.id) // Usar el ID del usuario actual
        .eq('proyecto_key', proyectoKey) // Usar la clave del proyecto
        .single();

      if (error) {
        console.error('Error al verificar el rol de admin:', error);
        return;
      }

      // Verificar si el perfil es 'admin'
      setIsAdmin(data?.perfil === 'admin');
    };

    checkAdmin();
  }, [proyectoKey, user.id]);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const formattedAddress = place.formatted_address;

      setFormData((prevData) => ({
        ...prevData,
        ubicacion: formattedAddress // Actualiza formData con la dirección formateada
      }));

      setSelectedValue(formattedAddress); // Actualiza el selectedValue con el valor seleccionado
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // No actualices previousEncargadoEmail aquí
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    const proyectoData = {
      proyecto: formData.proyecto,
      descripcion: formData.descripcion,
      ubicacion: formData.ubicacion,
      key: proyectoKey, // Usar la clave existente
      creador: user.id,
      url_imagen: imageUrl || null,
      url_carpeta: formData.url_carpeta,
      encargado: formData.encargado,
      correo: formData.correo,
      fecha_inicio: formData.fecha_inicio,
      fecha_termino: formData.fecha_termino,
    };

    // Lógica para subir la imagen solo si se seleccionó
    if (image) {
      try {
        const fileExt = image.name.split('.').pop();
        const fileName = `${proyectoData.key}/${Date.now()}.${fileExt}`;
        const filePath = `${fileName}`;

        const { error: uploadError } = await supabase
          .storage
          .from('proyectos')
          .upload(filePath, image);

        if (uploadError) {
          throw uploadError;
        }
        const imageUrlToUpdate = `https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/proyectos/${filePath}`;
        setImageUrl(imageUrlToUpdate);
        proyectoData.url_imagen = imageUrlToUpdate;

      } catch (error) {
        setError('Error al subir la imagen');
        setIsUpdating(false);
        return;
      }
    }


    // console.log("previousEncargadoEmail",previousEncargadoEmail);
    // console.log("formData.correo",formData.correo);
    // Lógica para enviar el correo de invitación si el correo ha cambiado
    if (formData.correo !== previousEncargadoEmail) {
      try {
        // Verificar si el correo anterior está en la tabla 'usuarios'
        const { data: usuarioData, error: usuarioError } = await supabase
          .from('usuarios')
          .select('user_id, nombre, apellido')
          .eq('email', previousEncargadoEmail)
          .single(); // Cambia a .single() para obtener solo un registro

        if (usuarioError && usuarioError.code !== 'PGRST116') {
          console.error('Error al obtener el user_id del encargado anterior:', usuarioError.message);
          throw usuarioError;
        }

        // Si no se encontró el usuario, solo elimina de 'invitados'
        if (!usuarioData) {
          console.log('El correo no está en la tabla usuarios, eliminando solo de invitados.');
          const { error: removeInvitadoError } = await supabase
            .from('invitados')
            .delete()
            .eq('email', previousEncargadoEmail)
            .eq('proyecto_key', proyectoData.key);

          if (removeInvitadoError) {
            console.error('Error al eliminar el invitado antiguo:', removeInvitadoError.message);
            throw removeInvitadoError;
          }
        } else {
          // Si se encontró el usuario, elimina de 'invitados' y 'colaboradores'
          const previousUserId = usuarioData.user_id;

          // Eliminar el registro del correo antiguo en 'invitados'
          const { error: removeInvitadoError } = await supabase
            .from('invitados')
            .delete()
            .eq('email', previousEncargadoEmail)
            .eq('proyecto_key', proyectoData.key);

          if (removeInvitadoError) {
            console.error('Error al eliminar el invitado antiguo:', removeInvitadoError.message);
            throw removeInvitadoError;
          }

          // Eliminar el registro del correo antiguo en 'colaboradores'
          const { error: removeColaboradorError } = await supabase
            .from('colaboradores')
            .delete()
            .eq('usuario_id', previousUserId) // Usar el user_id obtenido
            .eq('proyecto_key', proyectoData.key);

          if (removeColaboradorError) {
            console.error('Error al eliminar el colaborador antiguo:', removeColaboradorError.message);
            throw removeColaboradorError;
          }
        }

        // Enviar nuevo correo de invitación
        console.log("formData.correo",formData.correo);
        console.log("enviando correo de invitacion");
        const invitadoData = {
          email: formData.correo,
          invitado_por: user.id,
          estado_invitacion: 'invitacion enviada',
          proyecto_key: proyectoData.key,
          perfil: 'encargado',
        };

        const { error: invitadoError } = await supabase
          .from('invitados')
          .insert([invitadoData]);

        if (invitadoError) {
          setError('Error al crear el invitado');
          setIsUpdating(false);
          return;
        }

        // Asegúrate de que los datos del nuevo encargado se envíen correctamente
        console.log('Datos para enviar el correo:', {
          email: formData.correo,
          usuario: {
            nombre: usuario?.nombre || '', // Usar el nombre del contexto de usuario
            apellido: usuario?.apellido || '', // Usar el apellido del contexto de usuario
          },
          perfil: 'encargado',
          proyecto: formData.proyecto,
          key: proyectoData.key,
        });

        await fetch('/.netlify/functions/sendEmailInvitacion', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formData.correo,
            usuario: {
              nombre: usuario?.nombre || '', // Usar el nombre del contexto de usuario
              apellido: usuario?.apellido || '', // Usar el apellido del contexto de usuario
            },
            perfil: 'encargado',
            proyecto: formData.proyecto,
            key: proyectoData.key,
          }),
        });
      } catch (error) {
        console.error('Error al enviar el correo de invitación:', error);
        setError('Error al enviar el correo de invitación');
        setIsUpdating(false);
        return;
      }
    }

    const { error: proyectoError } = await supabase
      .from('proyectos')
      .update(proyectoData)
      .eq('key', proyectoKey); // Actualizar el proyecto existente

    if (proyectoError) {
      setError('Error al actualizar el proyecto');
      setIsUpdating(false);
      return;
    }

    setIsUpdating(false); // Restablecer el estado al final
    navigate('/proyectos');
  };

  const handleCancel = () => {
    navigate('/proyectos');
  };

  const handleImageClick = () => {
    document.getElementById('imageInput').click(); // Abrir el cuadro de diálogo
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);

      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
    }
  };

  return (
    <>
      <h2 className='text-2xl font-bold mt-20'>Editar Proyecto</h2>
      <div className='container-mayor px-4 sm:px-6 lg:px-8'>
        <span className="material-symbols-outlined ico-cerrar-form" onClick={handleCancel}>
          cancel
        </span>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit} className="form-container">
          <div className="flex mb-10">
            <div className="image-upload w-1/3 flex flex-col items-center" onClick={handleImageClick}>
              <img src={imageUrl || 'https://moijefyfdkbabylhxjdv.supabase.co/storage/v1/object/public/usuarios/subir-logo.jpg'} alt="Imagen del proyecto" className="project-image" />
              <label className='text-xs'>Imagen del proyecto</label>
              <input type="file" accept="image/*" id="imageInput" style={{ display: 'none' }} onChange={handleImageChange} />
            </div>
            <div className="description-container w-2/3 ml-2">
              <label className="text-left">Descripción del proyecto:</label>
              <textarea name="descripcion" value={formData.descripcion} onChange={handleChange} className="w-full" />
            </div>
          </div>
          <div className="form-row">
            <div>
              <label className="text-left">Nombre Proyecto:</label>
              <input type="text" name="proyecto" value={formData.proyecto} onChange={handleChange} required />
            </div>

            <div>
              <label className="text-left">Ubicación:</label>
              {isApiLoaded && (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  options={{
                    componentRestrictions: { country: 'CL' }
                  }}
                >
                  <input
                    type="text"
                    value={selectedValue}
                    onChange={(e) => {
                      setSelectedValue(e.target.value);
                    }}
                    name="ubicacion"
                  />
                </Autocomplete>
              )}
            </div>
          </div>

          <div className="form-row">
            <div>
              <label className="text-left">Fecha de Inicio:</label>
              <input type="date" name="fecha_inicio" value={formData.fecha_inicio} onChange={handleChange} className="w-full h-10 rounded p-2" />
            </div>
            <div>
              <label className="text-left">Fecha de Término:</label>
              <input type="date" name="fecha_termino" value={formData.fecha_termino} onChange={handleChange} className="w-full h-10 rounded p-2" />
            </div>
          </div>
          <div className="form-row">
            <div>
              <label className="text-left">Nombre Encargado:</label>
              <input 
                type="text" 
                name="encargado" 
                value={formData.encargado} 
                onChange={isAdmin ? handleChange : undefined} // Solo permitir cambio si es admin
                disabled={!isAdmin} // Deshabilitar si no es admin
              />
            </div>
            <div>
              <label className="text-left">Correo Encargado:</label>
              <input 
                type="email" 
                name="correo" 
                value={formData.correo} 
                onChange={isAdmin ? handleChange : undefined} // Solo permitir cambio si es admin
                className="w-full h-10 rounded p-2" 
                disabled={!isAdmin} // Deshabilitar si no es admin
              />
            </div>
          </div>

          <div className="text-center">
            <p className="mt-10 font-bold">AGREGA EL LINK DE TU CARPETA DE PROYECTO</p>
            <p className="text-xs">Google Drive, Dropbox, OneDrive, ETC...</p>
            <br />
            <input type="url" name="url_carpeta" onChange={handleChange} value={formData.url_carpeta} className="w-80 h-10 rounded p-2" />
          </div>
          <button className="btn-azul" type="submit" disabled={isUpdating}>
            {isUpdating ? 'Actualizando Proyecto' : 'Guardar'}
            {isUpdating && <span className="loading loading-dots loading-xs pt-4 ml-2"></span>}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditarProyecto;
