import React from 'react';
import './ProyectoSkeleton.css'; // Asegúrate de crear este archivo CSS

const ProyectoSkeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-header">
        <div className="skeleton-avatar"></div>
        <div className="skeleton-title"></div>
      </div>
      <div className="skeleton-body">
        <div className="skeleton-field"></div>
        <div className="skeleton-field"></div>
        <div className="skeleton-field"></div>
      </div>
      <div className="skeleton-footer">
        <div className="skeleton-button"></div>
      </div>
    </div>
  );
};

export default ProyectoSkeleton;