import React, { useEffect, useState, useRef, useContext } from 'react';
import { supabase } from '../../lib/supabaseClient';
import { ChatContext } from '../../context/ChatContext';
import { motion } from 'framer-motion';

// Define las variantes de animación
const chatVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const ChatProyecto = ({ proyectoKey, usuario, urlImagen, nombre, apellido, nombreProyecto, imagenProyecto, onClose, bottomOffset }) => {

  const { chatPositions } = useContext(ChatContext);
  const positionIndex = chatPositions.indexOf(proyectoKey);
  const [isChatVisible, setIsChatVisible] = useState(true);
  const chatWidth = 350; // Ancho del chat
  const baseRightOffset = 100; // Punto de inicio fijo para chats abiertos
  const adjustedRightOffset = isChatVisible ? baseRightOffset + positionIndex * (chatWidth + 20) : 20; // Desplazamiento horizontal dinámico
  const adjustedVerticalOffset = positionIndex * 60; // Desplazamiento vertical para logos
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  const handleCloseChat = () => {
    setIsChatVisible(false);
    onClose();
  };

  useEffect(() => {
    // console.log('ChatProyecto montado para:', proyectoKey);

    const fetchMessages = async () => {
      const { data, error } = await supabase
        .from('chat')
        .select(`
          *,
          usuarios (
            nombre,
            apellido,
            url_imagen
          )
        `)
        .eq('proyecto', proyectoKey)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching messages:', error);
      } else {
        setMessages(data);
      }
    };

    fetchMessages();

    const subscription = supabase
      .channel('public:chat')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'chat', filter: `proyecto=eq.${proyectoKey}` }, async payload => {
        const newMessage = payload.new;
        const { data: userData, error: userError } = await supabase
          .from('usuarios')
          .select('nombre, apellido, url_imagen')
          .eq('user_id', newMessage.usuario)
          .single();

        if (userError) {
          console.error('Error fetching user data:', userError);
        } else if (userData) {
          newMessage.usuarios = userData;
          setMessages(prevMessages => [...prevMessages, newMessage]);
        }
      })
      .subscribe();

    return () => {
      if (subscription) {
        supabase.removeChannel(subscription);
      }
    };
  }, [proyectoKey]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    // console.log('Usuario:', usuario);
    if (newMessage.trim() === '' || !usuario) {
      console.error('Usuario no definido o mensaje vacío');
      return;
    }

    const { error } = await supabase
      .from('chat')
      .insert([{ proyecto: proyectoKey, usuario, mensaje: newMessage }]);

    if (error) {
      console.error('Error sending message:', error);
    } else {
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const adjustedBottomOffset = bottomOffset > 0 ? bottomOffset : 20;

  return (
    <motion.div
      className={`fixed bg-white shadow-lg ${isChatVisible ? 'w-full md:w-1/3 h-auto' : 'w-12 h-12 rounded-full'}`}
      style={{
        bottom: isChatVisible ? `${adjustedBottomOffset}px` : `${adjustedBottomOffset + adjustedVerticalOffset}px`,
        right: `${adjustedRightOffset}px`,
        maxWidth: `${chatWidth}px`
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={chatVariants}
      transition={{ duration: 0.5 }}
    >
      {isChatVisible ? (
        <div>
          <div className="flex justify-between items-center p-2 bg-[var(--color-atention)] text-white">
            <h2 className="text-xl font-bold">{nombreProyecto}</h2>
            <div className="flex space-x-2">
              <button onClick={() => setIsChatVisible(!isChatVisible)} className="material-symbols-outlined">
                minimize
              </button>
              <button onClick={handleCloseChat} className="material-symbols-outlined">
                close
              </button>
            </div>
          </div>
          <div className="chat-messages mt-1 p-2" style={{ maxHeight: '350px', overflowY: 'auto' }}>
            {messages.map((msg, index) => (
              <div key={index} className={`chat ${msg.usuario === usuario ? 'chat-end' : 'chat-start'}`}>
                <div className="chat-image avatar">
                  <div className="w-10 rounded-full">
                    <img
                      alt="User Avatar"
                      src={msg.usuario === usuario ? urlImagen : msg.usuarios?.url_imagen || "https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"}
                    />
                  </div>
                </div>
                <div className="chat-header">
                  {msg.usuario === usuario ? `${nombre} ${apellido}` : `${msg.usuarios?.nombre} ${msg.usuarios?.apellido}`}
                </div>
                <div className={`chat-bubble ${msg.usuario === usuario ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
                  {msg.mensaje}
                </div>
                <div className="chat-footer opacity-50">
                  {new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} 
                  {new Date(msg.created_at).toDateString() !== new Date().toDateString() && (
                    <span> - {new Date(msg.created_at).toLocaleDateString('es-ES', { weekday: 'long' })}</span>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input mt-2 p-2">
            <div className="flex items-center">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Escribe un mensaje..."
                className="border p-3 rounded w-full text-[var(--background)]"
              />
              <button className="bg-[var(--color-atention)] p-2 rounded-md ml-2 text-white" onClick={handleSendMessage}>
                <span className="material-symbols-outlined">send</span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full cursor-pointer" onClick={() => setIsChatVisible(true)}>
          <img
            src={imagenProyecto}
            alt="Imagen del Proyecto"
            className="w-10 h-10 rounded-full object-cover"
          />
        </div>
      )}
    </motion.div>
  );
};

export default ChatProyecto;
