import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Volver = ({ titulo }) => {
  const navigate = useNavigate();

  return (
    <motion.div 
      className="relative w-full" 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold text-center">{titulo}</h1>
      <div className="btn-volver">
        <div className="flex items-center" onClick={() => navigate(-1)}>
          <div className="back circular border">
            <span className="material-symbols-outlined">arrow_back</span>
          </div>
          <span className="text-xs cursor-pointer">Volver</span>
        </div>
      </div>
    </motion.div>
  );
};

export default Volver;