import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import stars from '../../assets/img/stars.png';

// Función para capitalizar palabras
const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
};

const ReportesLayout = ({ reportes, showMap, toggleMap }) => {
  return (
    <div>
      <button
        onClick={toggleMap}
        className="flex items-center justify-center mb-4"
      >
        <img src={showMap ? '/path/to/qr-icon.png' : '/path/to/map-icon.png'} alt="Toggle Icon" className="w-6 h-6 mr-2" />
        {showMap ? 'Ver QR' : 'Ver Mapa de Calor'}
      </button>
      <div className="flex flex-wrap justify-center gap-2">
        {reportes.map((reporte) => (
          <motion.div
            key={reporte.id}
            className="bg-white p-4 border rounded shadow mt-4 max-w-[250px] max-h-[400px] mx-auto flex flex-col"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div>
              <p className="text-sm text-gray-500 text-center">{new Date(reporte.fecha_creacion).toLocaleDateString()}</p>
              <Link to={`/proyecto/${reporte.proyecto_key}/reporte/${reporte.key}`}>
                <img
                  src={showMap ? reporte.url_mapa_calor : reporte.url_qr}
                  alt={showMap ? "Mapa de Calor" : "QR Code"}
                  className="w-full h-40 object-cover mx-auto cursor-pointer"
                />
              </Link>
              <h2 className="text-m font-bold text-left ml-2 mb-2">
                <Link to={`/proyecto/${reporte.proyecto_key}/reporte/${reporte.key}`}>{reporte.reporte}</Link>
              </h2>
            </div>
            <div className="mt-auto">
              <p className="text-center"><strong className="text-sm">Encargado: <br /> </strong> {capitalizeWords(reporte.encargado)}</p>
              {reporte.nota_puntos1 && (
                <div className="flex justify-center items-center mt-2 mb-4">
                  <img 
                    src={stars} 
                    alt="Estrella" 
                    className="w-6 h-6 mr-4" 
                  />
                  <p className="text-2xl text-gray-500">{reporte.nota_puntos1}</p>
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ReportesLayout;