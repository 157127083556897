import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useChat } from '../../context/ChatContext';
import ProyectoSkeleton from './ProyectoSkeleton';
import ReportesLayout from '../reportes/ReportesLayout';
import chatIcon from '../../assets/img/chat.png';

const Proyecto = () => {
  const { proyectoKey } = useParams();
  const [proyecto, setProyecto] = useState(null);
  const [reportes, setReportes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [usuarioProyecto, setUsuarioProyecto] = useState(null);
  const [activeTab, setActiveTab] = useState('reportes');
  const { toggleChat } = useChat();
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const fetchProyectoAndReportes = async () => {
      try {
        const { data: proyectoData, error: proyectoError } = await supabase
          .from('proyectos')
          .select('*')
          .eq('key', proyectoKey)
          .single();

        if (proyectoError) {
          setError(proyectoError.message);
          setLoading(false);
          return;
        }

        setProyecto(proyectoData);

        const { data: reportesData, error: reportesError } = await supabase
          .from('reportes')
          .select('*')
          .eq('proyecto_key', proyectoKey);

        if (reportesError) {
          setError(reportesError.message);
          setLoading(false);
          return;
        }

        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: linksData, error: linksError } = await supabase
            .from('links')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (linksError) {
            setError(linksError.message);
            setLoading(false);
            return;
          }

          reporte.links_count = linksData.length;
        }

        for (let i = 0; i < reportesData.length; i++) {
          const reporte = reportesData[i];
          const { data: archivosData, error: archivosError } = await supabase
            .from('archivos')
            .select('id')
            .eq('reporte_key', reporte.key);

          if (archivosError) {
            setError(archivosError.message);
            setLoading(false);
            return;
          }

          reporte.archivos_count = archivosData.length;
        }

        setReportes(reportesData);
      } catch (error) {
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };

    if (proyectoKey) {
      fetchProyectoAndReportes();
    } else {
      setLoading(false);
      setError('proyectoKey is undefined');
    }
  }, [proyectoKey]);

  useEffect(() => {
    const fetchUsuarioProyecto = async () => {
      if (proyecto?.correo) {
        const { data: usuarioData, error: usuarioError } = await supabase
          .from('usuarios')
          .select('*')
          .eq('email', proyecto.correo)
          .single();

        if (usuarioError) {
          console.error('Error fetching user data:', usuarioError);
        } else {
          setUsuarioProyecto(usuarioData);
        }
      }
    };

    fetchUsuarioProyecto();
  }, [proyecto]);

  if (loading) return <ProyectoSkeleton />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4 mt-10 mb-20">
      <div className="relative w-full flex flex-col items-center">
        <h1 className="text-3xl font-bold text-center">
          Listado de Reportes <br /><br />
          <span className="text-2xl font-bold">
            {proyecto.proyecto}
            <span
              className="cursor-pointer ml-2 fixed bottom-4 right-16"
              onClick={() => toggleChat(proyectoKey, proyecto.proyecto, proyecto.url_imagen)}
            >
              <img src={chatIcon} alt="Chat Icon" className="w-10 h-10" />
            </span>
          </span>
        </h1>
        <img src={proyecto.url_imagen} alt="Imagen del Proyecto" className="w-20 h-20 rounded mb-4 mt-4" />
        <div className="btn-volver">
          <div className="flex items-center" onClick={() => navigate(-1)}>
            <div className="back circular border">
              <span className="material-symbols-outlined">arrow_back</span>
            </div>
            <span className="text-xs cursor-pointer">Volver</span>
          </div>
        </div>
      </div>
      
      <div className="flex justify-center items-center mt-20 mb-20">
        <div role="tablist" className="tabs tabs-lifted">
          <button
            role="tab"
            className={`tab ${activeTab === 'reportes' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('reportes')}
          >
            Reportes
          </button>
          <button
            role="tab"
            className={`tab ${activeTab === 'colaboradores' ? 'tab-active' : ''}`}
            onClick={() => setActiveTab('colaboradores')}
          >
            Colaboradores
          </button>
        </div>
      </div>

      {activeTab === 'reportes' && (
        <>
          <div className="flex justify-center mb-4">
            <button
              className="bg-[#D9E0E5] text-[#3A3A3A] py-6 px-10 rounded-xl flex items-center"
              onClick={() => navigate(`/proyecto/${proyectoKey}/crear-reporte`)}
            >
              <span className="material-symbols-outlined mr-2">add_circle</span>
              REPORTE
            </button>
          </div>
          
          <ReportesLayout reportes={reportes} showMap={showMap} toggleMap={() => setShowMap(!showMap)} />
        </>
      )}

      {activeTab === 'colaboradores' && usuarioProyecto && (
        <div className="">
          <div className="logo-empresa-container">
            <img src={usuarioProyecto.logo || 'url_imagen_grande'} alt="Logo Grande" className="logo-empresa" />
            <img src={usuarioProyecto.url_imagen || 'url_imagen_pequeno'} alt="Logo Pequeño" className="logo-usuario" />
          </div>
          <div className="empresa-info text-left">
            <span className="nombre-empresa">{usuarioProyecto.empresa || 'Tu Empresa'}</span>
            <span className="email-empresa">{usuarioProyecto.email || 'cargando email'}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Proyecto;
