import React from 'react';
import { useUsuario } from '../context/usuario'; // Importa el hook useUsuario
import './Dashboard.css';

const Dashboard = () => {
  const { user, usuario, loading } = useUsuario();

  if (loading) {
    return <div>Cargando %...</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>Bienvenido a tu Dashboard</h1>
      <p>Usuario: {usuario?.username || user?.email}</p>
      {/* Aquí puedes agregar más contenido del dashboard */}
    </div>
  );
};

export default Dashboard;