import React, { createContext, useState, useContext } from 'react';

export const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState({});
  const [chatPositions, setChatPositions] = useState([]);

  const toggleChat = (proyectoKey, nombreProyecto, imagenProyecto) => {
    console.log(`[${new Date().toISOString()}] Toggling chat for project: ${proyectoKey}`);
    setChats((prevChats) => {
      const isOpen = !prevChats[proyectoKey]?.isOpen;
      const newChats = {
        ...prevChats,
        [proyectoKey]: {
          isOpen,
          nombreProyecto: nombreProyecto || prevChats[proyectoKey]?.nombreProyecto,
          imagenProyecto: imagenProyecto || prevChats[proyectoKey]?.imagenProyecto,
        },
      };
      console.log(`[${new Date().toISOString()}] Updated Chats:`, newChats);
      return newChats;
    });

    setChatPositions((prevPositions) => {
      if (!prevPositions.includes(proyectoKey)) {
        return [...prevPositions, proyectoKey];
      }
      return prevPositions;
    });
  };

  return (
    <ChatContext.Provider value={{ chats, toggleChat, chatPositions }}>
      {children}
    </ChatContext.Provider>
  );
};